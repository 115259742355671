import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL } from '../../../configs'
import { BACKEND_URL } from '../../../configs'
import { savePopularHoursStatistics } from '../actions/popularHoursStatistics'
import { saveSupportStatistics } from '../actions/supportStatistics'
import { saveUsersStatistics } from '../actions/usersStatistics'

export const loadUsersStatistics = (botId, from, to, hourly) => {
  const loadDataUrl = !hourly
    ? `${BASE_URL}/chart/users/${botId}?from=${from}&to=${to}&allChannels=true`
    : `${BASE_URL}/chart/users/${botId}/last24hours?allChannels=true`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveUsersStatistics(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load user statistics data!')
    })
}

export const loadSupportStatistics = (botId, from, to) => {
  return fetch(`${BASE_URL}/chart/conversationCovering/${botId}?from=${from}&to=${to}`, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveSupportStatistics(responseJson)
    })
    .catch(() => {
      alertError('Not able to load chatbot/support statistics data!')
    })
}

export const loadPopularHoursStatistics = (botId, from, to) => {
  return fetch(`${BASE_URL}/chart/hourlyConversation/${botId}?from=${from}&to=${to}`, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      savePopularHoursStatistics(responseJson)
    })
    .catch(() => {
      alertError('Not able to load popular hours statistics data!')
    })
}

export const knowledgeBaseTransferring = (botId, destinationBotId, destinaitonUrl) => {
  // eslint-disable-next-line max-len
  return fetch(`${BACKEND_URL}/migrate/knowledgebase?sourceBotId=${botId}&destinationBotId=${destinationBotId}&destinationUrl=${destinaitonUrl}`, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => {
      alertError('Not able to transfer knowledge base data')
    })
}
