import styled from 'styled-components'

export const KnowledgeTransferringModalContainer = styled.div`
  padding: 0 36px 36px;
`

export const KnowledgeTransferringInputContainer = styled.div`
  margin-bottom: 24px;
  
  &:last-child {
    margin-bottom: 0;
  }
`
