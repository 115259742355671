import React, { FC, useState } from 'react'
import ModalTemplate from 'uiKit/ModalTemplate'
import Input from 'uiKit/inputs/Input'
import { knowledgeBaseTransferring } from '../../api/dashboard'
import { ZERO_KEY, NINE_KEY, BACKSPACE_KEY, DELETE_KEY } from 'constants/keyCodes'
import {
  KnowledgeTransferringInputContainer,
  KnowledgeTransferringModalContainer,
} from './KnowledgeTransferringModal.styles'

const inputTitleStyles = {
  fontSize: 16,
  fontWeight: 600,
  marginTop: 0
}

interface Props {
  botId: string
  isKnowledgeModalOpen: boolean
  setIsKnowledgeModalOpen: (isOpen: boolean) => void
}

export const KnowledgeTransferringModal: FC<Props> = ({
  botId,
  isKnowledgeModalOpen,
  setIsKnowledgeModalOpen
}) => {
  const [errors, setErrors] = useState({
    destinationURL: '',
    destinationBotID: ''
  })

  const [form, setForm] = useState({
    destinationURL: '',
    destinationBotID: ''
  })

  const handleSave = () => {
    if (!form.destinationURL.length) {
      setErrors(prevState => ({
        ...prevState,
        destinationURL: 'Destination URL can’t be empty'
      }))
    }
    if (!form.destinationBotID.length) {
      setErrors(prevState => ({
        ...prevState,
        destinationBotID: 'Destination bot ID can’t be empty'
      }))
    }

    if (!form.destinationURL.length || !form.destinationBotID.length) {
      return
    }
    setIsKnowledgeModalOpen(false)
    knowledgeBaseTransferring(botId, form.destinationBotID, form.destinationURL)
  }

  const onChangeHandler = (value: string, type: string) => {
    setErrors(prevState => ({
      ...prevState,
      [type]: ''
    }))
    setForm(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const handleKeyDown = (event) => {
    if (
      (event.keyCode >= ZERO_KEY && event.keyCode <= NINE_KEY) ||
      [BACKSPACE_KEY, DELETE_KEY].includes(event.keyCode)
    ) {
      return event
    } else {
      event.preventDefault()
    }
  }

  return (
    <ModalTemplate
      open={isKnowledgeModalOpen}
      title='Send AI Knowledge to Prod'
      onClose={() => setIsKnowledgeModalOpen(false)}
      onSave={handleSave}
    >
      <KnowledgeTransferringModalContainer>
        <KnowledgeTransferringInputContainer>
          <Input
            inputTitleStyles={inputTitleStyles}
            error={!!errors.destinationURL}
            errorMessage={errors.destinationURL}
            title='Destination URL'
            placeholder='Enter here'
            value={form.destinationURL}
            onChange={(e) => onChangeHandler(e.target.value, 'destinationURL')}
          />
        </KnowledgeTransferringInputContainer>
        <KnowledgeTransferringInputContainer>
          <Input
            inputTitleStyles={inputTitleStyles}
            error={!!errors.destinationBotID}
            errorMessage={errors.destinationBotID}
            title='Destination bot ID'
            placeholder='Enter here'
            value={form.destinationBotID}
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeHandler(e.target.value, 'destinationBotID')}
          />
        </KnowledgeTransferringInputContainer>
      </KnowledgeTransferringModalContainer>
    </ModalTemplate>
  )
}
